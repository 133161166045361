import React from 'react';

import Header from '../../greelandComponents/header';
import Footer from '../../greelandComponents/footer';
import ImageURLs from '../../greenlandAssets/images';
import PageTitle from '../../components/pageTitle';
import JoinUsForm from './components/joinUsForm';
import HowItWorksSection from './components/howItworks';

function ContactPage() {
    return (
      <div className="contact-page">
        <Header/>
        <PageTitle
          title="Join Us"
          backgroundImage={ImageURLs.thinBannerImageURL}
        />
        <div className="container">
        <JoinUsForm />
        <section id="join-us"></section><HowItWorksSection /><br/>
        </div>

        <Footer/>
      </div>
    );
  }
  
  export default ContactPage;