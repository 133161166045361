import { 
   
    API_BASE_URL, 
    CLIENT_SECRET,
    accesstoken,
    ACCESS_KEY } from "./BaseUrls";

// const axios = require('axios').default;
import axios from 'axios';

const request = (options) => {
    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'utm-source': 'ERP' 
    }


    if (localStorage.getItem(accesstoken)) {
        // console.log(localStorage.getItem(accesstoken));
        // axios.defaults.headers.common["Authorization"] = accesstoken;
        headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'utm-source': 'ERP', 
            'accesstoken': {accesstoken},
            'Authorization': `${localStorage.getItem(accesstoken)}`
        }
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    // console.log(options.headers);
    // var postmethod = options.method;
    if (options.method === "POST") {

        return axios.post(options.url, options.body, { headers: headers }).then(function(response) {
            //  console.log(response);
            return response;
        }).catch(function(err) {
            // console.log(err);
           if(err.request && err.request.status === 403) {
            let appState = {
                isLoggedIn: false,
                user: {}
              };
              localStorage.removeItem("accesstoken");
              localStorage.removeItem("user");
              localStorage["appState"] = JSON.stringify(appState);
            //   this.props.history.push('/login');
            window.location.reload();
        }
            // console.log(err.message)
            // console.log(err.request)
            const error = {
                error: err
            }

            return error;
        });
    }

    if (options.method === "GET") {
        return axios({
            method: 'get',
            url: `${options.url}`,
            data: options.body,
            headers: headers
        })
    }
    
    if (options.method === "CUSTOM_GET") {
        return axios({
            method: 'get',
            url: `${options.url}`,
            data: options.body,
            headers: headers
        })
    }

};

//log in users
export function post_to_waiting_list(data) {
    return request({
        url: API_BASE_URL + `users/waiting_list?${CLIENT_SECRET}`,
        method: 'POST',
        body: data
    });
}

//log in users
export function login(data) {
    return request({
        url: API_BASE_URL + `users/login?${CLIENT_SECRET}`,
        method: 'POST',
        body: data
    });
}

//signup users
export function signup(data) {
    return request({
        url: API_BASE_URL + `users/signup?${CLIENT_SECRET}`,
        method: 'POST',
        body: data
    });
}

//forgot password
export function forgotpassword(data) {
    return request({
        url: API_BASE_URL + `users/forgotpassword?${CLIENT_SECRET}`,
        method: 'POST',
        body: data
    });
}

//reset password
export function updatepassword(data) {
    return request({
        url: API_BASE_URL + `users/updatepassword/${data.token}?${CLIENT_SECRET}`,
        method: 'POST',
        body: data
    });
}

//resend verify email
export function ResendVerifyEmail(data) {
    return request({
        url: API_BASE_URL + `users/resend_email_verification_token/?${CLIENT_SECRET}`,
        method: 'POST',
        body: data
    });
}

//fetch all users
export function UserList(data) {
    return request({
        url: API_BASE_URL + `users/?${CLIENT_SECRET}&limit=10`,
        method: 'GET',
        body: data
    });
}

//fetch all users
export function SingleUser(data) {
    return request({
        url: API_BASE_URL + `users/${data.user_id}/?${CLIENT_SECRET}`,
        method: 'GET',
        body: data
    });
}

//fetch the backlink directory
export function BackLinksList(data) {
    return request({
        url: API_BASE_URL + `links/?${CLIENT_SECRET}&limit=10`,
        method: 'GET',
        body: data
    });
}

//add backlinks to database
export function AddBacklink(data) {
    return request({
        url: API_BASE_URL + `links/?${CLIENT_SECRET}`,
        method: 'POST',
        body: data
    });
}

//fetch single user's links 
export function UsersLinkList(data) {
    return request({
        url: API_BASE_URL + `links/user/${data.user_id}/?${CLIENT_SECRET}&limit=10`,
        method: 'GET',
        body: data
    });
}

//fetch the sites 
export function SitesList(data) {
    return request({
        url: API_BASE_URL + `sites/?${CLIENT_SECRET}&limit=10`,
        method: 'GET',
        body: data
    });
}

//fetch single user's sites 
export function UsersSitesList(data) {
    return request({
        url: API_BASE_URL + `sites/user/${data.user_id}/?${CLIENT_SECRET}&limit=10`,
        method: 'GET',
        body: data
    });
}

//add site to database
export function AddSite(data) {
    return request({
        url: API_BASE_URL + `sites/?${CLIENT_SECRET}`,
        method: 'POST',
        body: data
    });
}


//fetch the shops directory
export function ShopList(data) {
    return request({
        url: API_BASE_URL + `links/?${CLIENT_SECRET}&limit=10`,
        method: 'GET',
        body: data
    });
}

//fetch single premise
export function SingleShop(data) {
    return request({
        url: API_BASE_URL + `premises/?paylend_number=${data.paylend_number}&${CLIENT_SECRET}`,
        method: 'GET',
        body: data
    });
}

//fetch loans
export function LoanList(pageNo, limit) {
    return request({
        url: API_BASE_URL + `loans/?${CLIENT_SECRET}&limit=${limit}`,
        method: 'GET',
        // body: data
    });
}

//fetch shop wallet byuserId
export function FetchShopWallet(data) {
    return request({
        url: API_BASE_URL + `premise-wallets/?user_id=${data.user_id}&${CLIENT_SECRET}`,
        method: 'GET',
        body: data
    });
}

//fetch shop wallet by premise ID
export function FetchShopWalletByPremiseID(data) {
    return request({
        url: API_BASE_URL + `premise-wallets/?premise_id=${data.premise_id}&${CLIENT_SECRET}`,
        method: 'GET',
        body: data
    });
}

//fetch loans by premiseowner id
export function FetchPremiseLoans(data) {
    return request({
        url: API_BASE_URL + `loans/?premise_owner_id=${data.user_id}&${CLIENT_SECRET}`,
        method: 'GET',
        body: data
    });
}

//fetch premise transactions
export function FetchPremiseLoansTransactions(data) {
    return request({
        url: API_BASE_URL + `transactions/?premise_id=${data.premise_id}&${CLIENT_SECRET}`,
        method: 'GET',
        body: data
    });
}

//fetch all transactions
export function FetchTransactions(data) {
    return request({
        url: API_BASE_URL + `transactions/?${CLIENT_SECRET}&pageN0=2`,
        method: 'GET',
        body: data
    });
}

