import React, { useState } from 'react';

//API calls
import { post_to_waiting_list } from '../../../greenlandAPI/ApiUtils';

export default function JoinUsForm() {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [userType, setUserType] = useState('');
    const [message, setMessage] = useState('');
    const [consent, setConsent] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [displaySuccess, setDisplaySuccess] = useState('none');
    const [responseErrors, setResponseErrors] = useState([]);

    const handleEmailChange = (event) => setEmail(event.target.value);
    const handleNameChange = (event) => setName(event.target.value);
    const handleUserTypeChange = (event) => setUserType(event.target.value);
    const handleMessageChange = (event) => setMessage(event.target.value);
    const handleConsentChange = () => setConsent(!consent);
    const handlePhoneNumberChange = (event) => setPhoneNumber(event.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisplaySuccess('none')
        let data = {
            name,
            email,
            user_type: userType,
            message,
            consent,
            phone_number: phoneNumber
        }
        console.log(data);
        let response = await post_to_waiting_list(data)

        if (response.data?.status === "success") {
            setName('')
            setEmail('')
            setConsent(false)
            setPhoneNumber('')
            setMessage('')
            setUserType('')

        } else {
            setResponseErrors(response.error.response.data.errors)

        }
    }
    const errorElements = responseErrors.map((err, i) => (
        <div key={i} className="alert alert-danger" role="alert">
            {err}
        </div>
    ));
    return (
        <div className="contact-form container">
            <div style={{ display: `${displaySuccess}` }} className="alert alert-success" role="alert">
                Record stored succssfully!
            </div>
            <div>
                {errorElements}
            </div>
            <form onSubmit={handleSubmit} >
                <div className="row">
                    <div className="col">
                        <fieldset className='form-group'>
                            <div className="row">
                                <div className="col-md-6">
                                    <legend>Select the best option that describes you:</legend>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            required
                                            type="radio"
                                            onChange={handleUserTypeChange}
                                            name="inlineRadioOptions"
                                            id="inlineRadio1"
                                            value="Farmer" />
                                        <label className="form-check-label" htmlFor="inlineRadio1">Farmer</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input required className="form-check-input" type="radio" onChange={handleUserTypeChange} name="inlineRadioOptions" id="inlineRadio2" value="Vendor" />
                                        <label className="form-check-label" htmlFor="inlineRadio2">Vendor</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input required className="form-check-input" type="radio" onChange={handleUserTypeChange} name="inlineRadioOptions" id="inlineRadio3" value="Franchisee" />
                                        <label className="form-check-label" htmlFor="inlineRadio3">Franchisee</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input required className="form-check-input" type="radio" onChange={handleUserTypeChange} name="inlineRadioOptions" id="inlineRadio4" value="Food seeker" />
                                        <label className="form-check-label" htmlFor="inlineRadio4">Food seeker</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div className="form-group">
                            <input type="text" required name="txtName" className="form-control" onChange={handleNameChange} placeholder="Your Name *" value={name} />
                        </div>
                        <div className="form-group">
                            <input required type="text" name="txtEmail" className="form-control" onChange={handleEmailChange} placeholder="Your Email *" value={email} />
                        </div>
                        <div className="form-group">
                            <input required type="text" name="txtPhone" className="form-control" onChange={handlePhoneNumberChange} placeholder="Your Phone Number *" value={phoneNumber} />
                        </div>
                        <div className="form-group">
                            <textarea required name="txtMsg" className="form-control" onChange={handleMessageChange} placeholder="Your Message *" value={message} style={{ width: "100%", height: "150px" }}></textarea>
                        </div>
                        <div className="form-group">
                            <div class="form-check">
                                <input required className="form-check-input" onChange={handleConsentChange} type="checkbox" id="gridCheck1" />
                                <label className="form-check-label" htmlFor="gridCheck1">
                                    I agree to the terms and conditions
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="submit" name="btnSubmit" className="btn btn-primary" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}