import React from 'react';
import ImageURLs from '../../greenlandAssets/images';

function Header() {
    
    return (
        <div className="Header">
            <nav className="navbar navbar-expand-lg  navbar-dark bg-dark">
                <div className="container">
                <a className="navbar-brand" href="/">
                <img src={ImageURLs.logoUrl} width="100" height="100" alt="logo" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item active">
                            <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#about-us">About us</a>
                        </li>
                    
                        <li className="nav-item">
                            <a className="nav-link" href="/join-us">Join us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#work-flow">Our process</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#how-it-works">How it works</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/contact-us">Contact us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#faq">FAQs</a>
                        </li>
                        
                    </ul>
                </div>
                
                    </div>
            </nav>

        </div>
    );
}

export default Header;